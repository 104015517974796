import { GamePoolWithDetail } from "../../models/GamePoolWithDetail";
import { SwapObject } from "../../models/SwapObject";
import { Trade } from "../../models/Trade";
import { User } from "../../models/User";
import { UserPayout } from "../../models/UserPayout";
// import { UserToken } from "../../models/UserToken";
import * as types from "../types/types";

export const setAddress = (address: string) => {
    return {
        type: types.SET_ADDRESS,
        data: address
    };
};

export const setUser = (user: User | null) => {
    return {
        type: types.SET_USER,
        data: user
    };
};

export const setUserPayouts = (userPayouts: UserPayout[]) => {
    return {
        type: types.SET_USER_PAYOUTS,
        data: userPayouts
    };
};

export const setTrades = (trades: Trade[]) => {
    return {
        type: types.SET_TRADES,
        data: trades
    };
};

export const setSport = (sport: string) => {
    return {
        type: types.SET_SPORT,
        data: sport
    };
};

export const setSwapFromToken = (address: string) => {
    return {
        type: types.SET_SWAP_FROM_TOKEN,
        data: address
    };
};

export const setSwapObject = (swapObject: SwapObject | null) => {
    return {
        type: types.SET_SWAP_OBJECT,
        data: swapObject
    };
};

export const setSwapToToken = (address: string) => {
    return {
        type: types.SET_SWAP_TO_TOKEN,
        data: address
    };
};

export const setGamePools = (gamePools: GamePoolWithDetail[]) => {
    return {
        type: types.SET_GAME_POOLS,
        data: gamePools
    };
};

export const setUserPropsBalance = (balance: number) => {
    return {
        type: types.SET_USER_PROPS_BALANCE,
        data: balance
    };
};
export const setUserBonusBalance = (balance: number) => {
    return {
        type: types.SET_USER_BONUS_BALANCE,
        data: balance
    };
};

export const setLeaderboard = (balance: number) => {
    return {
        type: types.SET_LEADERBOARD,
        data: balance
    };
};

export const setUserTrades = (userTrades: Trade[]) => {
    return {
        type: types.SET_USER_TRADES,
        data: userTrades
    };
};

// export const setUserTokens = (userTokens: UserToken[]) => {
//     return {
//         type: types.SET_USER_TOKENS,
//         data: userTokens
//     };
// };

export const setOdds = (odds: string) => {
    return {
        type: types.SET_ODDS,
        data: odds
    };
};

export const setLoadingPools = (loading: boolean) => {
    return {
        type: types.SET_LOADING_POOLS,
        data: loading
    };
};