// a betting pool for a given game
export class GamePool {
    public gameId: string;
    public homeTeamWin: boolean | null;
    public homeTeamTie: boolean | null;
    public gameCancelled: boolean | null;
    public homeTokenValue: number | null;
    public awayTokenValue: number | null;
    public tieTokenValue: number | null;
    public gameStartTime: number;
    public hidden: boolean;
    public week: string;
    public sport: string;
    public homeTeam: string;
    public awayTeam: string;
    public homeTeamName: string;
    public awayTeamName: string;
    public wagerTokenDecimals: number;
    public poolWagerTokenCap: number;
    public userTeamTokenCap: number;
    public feeRate: number;

	public signer: string;
	public poolAccount: string;
	public homePoolAccount: string;
    public awayPoolAccount: string;
    public tiePoolAccount: string;

	public homeTeamToken: string;
    public awayTeamToken: string;
    public tieTeamToken: string;
    public wagerToken: string;

	public homePoolTeamTokenAccount: string;
    public awayPoolTeamTokenAccount: string;
    public tiePoolTeamTokenAccount: string;
    public homePoolWagerTokenAccount: string;
    public awayPoolWagerTokenAccount: string;
    public tiePoolWagerTokenAccount: string;

	public protocolHomeTeamTokenAccount: string;
    public protocolAwayTeamTokenAccount: string;
    public protocolTieTeamTokenAccount: string;
    public protocolWagerTokenAccount: string;
    public feeReceiverWagerTokenAccount: string;

    constructor(
        gameId: string = ''
        , homeTeamWin: boolean | null = null
        , homeTeamTie: boolean | null = null
        , gameCancelled: boolean | null = null
        , homeTokenValue: number | null = null
        , awayTokenValue: number | null = null
        , tieTokenValue: number | null = null
        , gameStartTime: number = 0
        , hidden: boolean = false
        , week: string = ''
        , sport: string = ''
        , homeTeam: string = ''
        , awayTeam: string = ''
        , tieTeam: string = ''
        , homeTeamName: string = ''
        , awayTeamName: string = ''
        , tieTeamName: string = ''
        , wagerTokenDecimals: number = 0
        , poolWagerTokenCap: number = 0
        , userTeamTokenCap: number = 0
        , feeRate: number = 0

		, signer: string = ''
		, poolAccount: string = ''
		, homePoolAccount: string = ''
		, awayPoolAccount: string = ''
		, tiePoolAccount: string = ''

		, homeTeamToken: string = ''
        , awayTeamToken: string = ''
        , tieTeamToken: string = ''
        , wagerToken: string = ''

		, homePoolTeamTokenAccount: string = ''
        , awayPoolTeamTokenAccount: string = ''
        , tiePoolTeamTokenAccount: string = ''
        , homePoolWagerTokenAccount: string = ''
        , awayPoolWagerTokenAccount: string = ''
        , tiePoolWagerTokenAccount: string = ''
        , protocolHomeTeamTokenAccount: string = ''
        , protocolAwayTeamTokenAccount: string = ''
        , protocolTieTeamTokenAccount: string = ''
        , protocolWagerTokenAccount: string = ''
        , feeReceiverWagerTokenAccount: string = ''
    ) {
        this.gameId = gameId;
        this.homeTeamWin = homeTeamWin;
        this.homeTeamTie = homeTeamTie;
        this.gameCancelled = gameCancelled;
        this.homeTokenValue = homeTokenValue;
        this.awayTokenValue = awayTokenValue;
        this.tieTokenValue = tieTokenValue;
        this.week = week;
        this.sport = sport;
        this.gameStartTime = gameStartTime;
        this.hidden = hidden;
        this.homeTeam = homeTeam;
        this.awayTeam = awayTeam;
        this.homeTeamName = homeTeamName;
        this.awayTeamName = awayTeamName;
        this.wagerTokenDecimals = wagerTokenDecimals;
        this.userTeamTokenCap = userTeamTokenCap;
        this.poolWagerTokenCap = poolWagerTokenCap;
        this.feeRate = feeRate;

		this.signer = signer;
		this.poolAccount = poolAccount;
		this.homePoolAccount = homePoolAccount;
		this.awayPoolAccount = awayPoolAccount;
		this.tiePoolAccount = tiePoolAccount;

		this.homeTeamToken = homeTeamToken;
        this.awayTeamToken = awayTeamToken;
        this.tieTeamToken = tieTeamToken;
        this.wagerToken = wagerToken;

		this.homePoolTeamTokenAccount = homePoolTeamTokenAccount;
        this.awayPoolTeamTokenAccount = awayPoolTeamTokenAccount;
        this.tiePoolTeamTokenAccount = tiePoolTeamTokenAccount;
        this.homePoolWagerTokenAccount = homePoolWagerTokenAccount;
        this.awayPoolWagerTokenAccount = awayPoolWagerTokenAccount;
        this.tiePoolWagerTokenAccount = tiePoolWagerTokenAccount;

		this.protocolHomeTeamTokenAccount = protocolHomeTeamTokenAccount;
        this.protocolAwayTeamTokenAccount = protocolAwayTeamTokenAccount;
        this.protocolTieTeamTokenAccount = protocolTieTeamTokenAccount;
        this.protocolWagerTokenAccount = protocolWagerTokenAccount;
        this.feeReceiverWagerTokenAccount = feeReceiverWagerTokenAccount;
    }
}