import { Outcome } from '../models/Outcome';
import { GamePoolWithDetail } from '../models/GamePoolWithDetail';



// gamePool, outcome, isToTeamToken
export interface ITokenObject {
	tokenString: string;
	isRealToken: boolean;
	gamePool: GamePoolWithDetail | null;
	outcome: Outcome | null;
}

export class TokenObject implements ITokenObject {
	tokenString: string;
	isRealToken: boolean;
	gamePool: GamePoolWithDetail;
	outcome: Outcome;
	team: string;
	requiredTokenString: string;
	userBalance: number;

	constructor(
        tokenString: string = 'SOL'
        , isRealToken: boolean = true
        , gamePool: GamePoolWithDetail = new GamePoolWithDetail()
        , outcome: Outcome = Outcome.Home
        , team: string = 'SOL'
        , requiredTokenString: string = 'SOL'
        , userBalance: number = 0
    ) {
        this.tokenString = tokenString;
        this.isRealToken = isRealToken;
        this.gamePool = gamePool;
        this.outcome = outcome;
        this.team = team;
        this.requiredTokenString = requiredTokenString;
        this.userBalance = userBalance;
    }

}
