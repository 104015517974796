import { PublicKey } from "@solana/web3.js";
import { web3, BN } from '@project-serum/anchor';
import { TOKEN_PROGRAM_ID } from '@solana/spl-token';

// RPC call to pay out the user's team tokens
export async function payOutTeamTokensRpc(
    program: any,
    numTokens: number,

	poolAccount: PublicKey,
    userAuthority: PublicKey,

	homePoolAccount: PublicKey,
	awayPoolAccount: PublicKey,

	teamToken: PublicKey,
	homeTeamToken: PublicKey,
	awayTeamToken: PublicKey,
	wagerToken: PublicKey,

	userTeamTokenAccount: PublicKey,
	homePoolWagerTokenAccount: PublicKey,
	awayPoolWagerTokenAccount: PublicKey,
	userWagerTokenAccount: PublicKey,
	feeReceiverWagerTokenAccount: PublicKey,
	feeReceiverAccount: PublicKey,
) {
	try {
		console.log({
			'poolAccount': poolAccount.toString()
			, 'userAuthority': userAuthority.toString()
			, 'homePoolAccount': homePoolAccount.toString()
			, 'awayPoolAccount': awayPoolAccount.toString()
			, 'teamToken': teamToken.toString()
			, 'homeTeamToken': homeTeamToken.toString()
			, 'awayTeamToken': awayTeamToken.toString()
			, 'wagerToken': wagerToken.toString()
			, 'userTeamTokenAccount': userTeamTokenAccount.toString()
			, 'homePoolWagerTokenAccount': homePoolWagerTokenAccount.toString()
			, 'awayPoolWagerTokenAccount': awayPoolWagerTokenAccount.toString()
			, 'userWagerTokenAccount': userWagerTokenAccount.toString()
			, 'feeReceiverWagerTokenAccount': feeReceiverWagerTokenAccount.toString()
			, 'feeReceiverAccount': feeReceiverAccount.toString()
		});
		console.log(`payOutTeamTokensNew`);
		
		
		return await program.rpc.payOutTeamTokensNew(
			new BN(numTokens),
			{
				accounts: {
					poolAccount,
					userAuthority,

					homePoolAccount,
					awayPoolAccount,
					teamToken,

					homeTeamToken,
					awayTeamToken,
					wagerToken,

					userTeamTokenAccount,
					homePoolWagerTokenAccount,
					awayPoolWagerTokenAccount,
					userWagerTokenAccount,
					feeReceiverWagerTokenAccount,
					feeReceiverAccount,
					userAccount: userAuthority,

					tokenProgram: TOKEN_PROGRAM_ID,
					clock: web3.SYSVAR_CLOCK_PUBKEY,
					systemProgram: web3.SystemProgram.programId,
				},
			}
		);
	}
	catch (err) {
		console.log(`Error`);
		console.log(err);
	}
}


// RPC call to pay out the user's team tokens
export async function payOutTeamTokensTiePoolRpc(
    program: any,
    numTokens: number,

	poolAccount: PublicKey,
    userAuthority: PublicKey,

	homePoolAccount: PublicKey,
	awayPoolAccount: PublicKey,
	tiePoolAccount: PublicKey,

	teamToken: PublicKey,
	homeTeamToken: PublicKey,
	awayTeamToken: PublicKey,
	tieTeamToken: PublicKey,
	wagerToken: PublicKey,

	userTeamTokenAccount: PublicKey,
	homePoolWagerTokenAccount: PublicKey,
	awayPoolWagerTokenAccount: PublicKey,
	tiePoolWagerTokenAccount: PublicKey,
	userWagerTokenAccount: PublicKey,
	feeReceiverWagerTokenAccount: PublicKey,
	feeReceiverAccount: PublicKey,
) {
	try {
		console.log(`payOutTeamTokensTiePool`);
		
		return await program.rpc.payOutTeamTokensTiePool(
			new BN(numTokens),
			{
				accounts: {
					poolAccount,
					userAuthority,

					homePoolAccount,
					awayPoolAccount,
					tiePoolAccount,
					teamToken,

					homeTeamToken,
					awayTeamToken,
					tieTeamToken,
					wagerToken,

					userTeamTokenAccount,
					homePoolWagerTokenAccount,
					awayPoolWagerTokenAccount,
					tiePoolWagerTokenAccount,
					userWagerTokenAccount,
					feeReceiverWagerTokenAccount,
					feeReceiverAccount,
					userAccount: userAuthority,

					tokenProgram: TOKEN_PROGRAM_ID,
					clock: web3.SYSVAR_CLOCK_PUBKEY,
					systemProgram: web3.SystemProgram.programId,
				},
			}
		);
	}
	catch (err) {
		console.log(`Error`);
		console.log(err);
	}
}

